.barCard-title {
  text-align: center;
  font-weight: bold;
  font-family: "Montserrat", sans-serif;
}

@media (max-width: 576px) {
  .barCard-div {
    border-left: 5px solid orange;
    background-color: white;
    width: 80%;
    padding: 5px;
    margin: 10px 15px;
  }

  .barCard-body {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }

  .barCard-tall {
    height: 240px;
  }

  .barCard-short {
    height: 200px;
    padding: 0px 20px;
    padding-bottom: 20px;
  }
}

@media (min-width: 576px) {
  .barCard-div {
    border-left: 5px solid orange;
    background-color: white;
    width: 400px;
    padding: 5px;
    margin: 10px 15px;
  }

  .barCard-body {
    width: 100%;
    height: 90%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }

  .barCard-tall {
    height: 240px;
  }

  .barCard-short {
    height: 150px;
    padding: 0px 20px;
    padding-bottom: 20px;
  }
}
