.IconInfo-div img {
  width: 50px;
  height: 50px;
  margin-right: 8px;
  align-self: center;
}

@media (max-width: 576px) {
  .IconInfo-div {
    width: 100%;
  }
}

@media (min-width: 576px) {
  .IconInfo-div {
    width: 350px;
  }
}

.IconInfo-div div {
  align-self: center;
  font-family: 'Montserrat', sans-serif;
}

.IconInfo-normal {
  font-weight: normal;
}

.IconInfo-light {
  font-weight: lighter;
}

.IconInfo-bold {
  font-weight: bold;
}