@media (max-width: 991px) {
  .navlinks {
    width: 100%;
  }
}

.navlink {
  border: 1px solid rgb(181, 181, 181);
  background-color: black;
  border-radius: 2px;
  color: white;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 20px;
  padding-right: 20px;
  margin-right: 1px;
  margin-left: 1px;
  font-size: x-small;
  font-family: "Montserrat", sans-serif;
}

.navlink:focus,
.navlink:hover {
  color: rgb(124, 204, 3);
}

.navlink-dropdown {
  background-color: rgb(23, 23, 23);
}
