.IconTitledInfo-div img {
  width: 50px;
  height: 50px;
  margin-right: 8px;
  align-self: center;
}

.IconTitledInfo-div {
  width: 100%;
}

.IconTitledInfo-div>div {
  font-size: 17px;
  color: white;
  font-family: 'Montserrat', sans-serif;
}

.IconTitledInfo-div>div>div {
  font-size: 20px;
  color: black;
}