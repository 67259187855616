@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Poppins:wght@300&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200&family=Poppins:wght@500&display=swap');

h5,
h2,
h4,
h5,
h3 {
  font-family: "Montserrat", sans-serif;
  /* font-family: 'Poppins', sans-serif; */
}

.popin{
  font-family: 'Poppins', sans-serif;
}

* {
  box-sizing: border-box;
}

body {
  font-family: Verdana, sans-serif;
}

.mySlides {
  display: none;
}

img {
  vertical-align: middle;
}

/* Slideshow container */
.slideshow-container {
  max-width: 1000px;
  position: relative;
  margin: auto;
}

/* Next & previous buttons */
.prev,
.next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  padding: 16px;
  margin-top: -22px;
  color: white;
  font-weight: bold;
  font-size: 18px;
  transition: 0.6s ease;
  border-radius: 0 3px 3px 0;
  user-select: none;
}

/* Position the "next button" to the right */
.next {
  right: 0;
  border-radius: 3px 0 0 3px;
}

/* On hover, add a black background color with a little bit see-through */
.prev:hover,
.next:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

/* Caption text */
.text {
  color: #f2f2f2;
  font-size: 15px;
  padding: 8px 12px;
  position: absolute;
  bottom: 8px;
  width: 100%;
  text-align: center;
}

/* Number text (1/3 etc) */
.numbertext {
  color: #f2f2f2;
  font-size: 12px;
  padding: 8px 12px;
  position: absolute;
  top: 0;
}

/* The dots/bullets/indicators */
.dot {
  height: 15px;
  width: 15px;
  margin: 0 2px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
}

.active,
.dot:hover {
  background-color: #717171;
}

/* Fading animation */
.fade {
  animation-name: fade;
  animation-duration: 5s;
}

@keyframes fade {
  from {
    opacity: 0.1;
  }

  to {
    opacity: 1;
  }
}

/* On smaller screens, decrease text size */
@media only screen and (max-width: 300px) {
  .prev,
  .next,
  .text {
    font-size: 11px;
  }
}

.paragraph {
  font-family: "Poppins", sans-serif;
  font-size: 17px;
  color: rgba(255, 255, 255, 0.8);
  font-weight: lighter !important;
}

.paragraph2 {
  font-family: "Poppins", sans-serif;
  color: rgba(255, 255, 255, 0.2);
  font-weight: lighter !important;
}

/* @media (min-width: 768px) {
  .paragraph {
    width: 65%;
    font-size: 18px;
  }
}

@media (max-width: 768px) {
  .paragraph {
    width: 100%;
    font-size: 18px;
  }
} */

.bg-slideshow {
  background-color: rgba(0, 0, 0, 0.5);
  padding-bottom: 20px;
}

.background::before {
  position: absolute;
  background-color: rgb(51, 51, 51);
  width: 100%;
  height: 100%;
  content: " ";
}

.d-icon {
  /* background-color: red; */
  background-color: rgba(0, 0, 0, 0.9);
  padding: 6px 10px;
  border-radius: 100px;
  border: 3px solid white;
}

.top-icon {
  /* background-color: red; */
  background-color: rgba(0, 0, 0, 0.9);
  padding: 10px 15px;
  margin: 5px;
  border: 3px solid white;
}

.bg-general {
  background-color: rgba(0, 0, 0, 0.7);
  background-blend-mode: overlay;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.top-slider {
  background-color: rgba(0, 0, 0, 0.3);
  background-blend-mode: overlay;
  /* background-attachment: fixed; */
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

@media (min-width: 992px) {
  .navbar-toggler {
    border: none !important;
  }

  #navbarTogglerDemo01 {
    display: none !important;
  }
}

.navbar {
  background-color: white !important;
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
}

.about_link{
  color: white;
  transition: 0.5s;
}

.about_link:hover{
  color: rgba(0, 0, 0, 0.5);
  transition: 0.5s;
}

.email{
  font-size: 13px;
}

@media (min-width: 767px) {
  .email{
    font-size: 17px;
  }
}


.readMore{
  transition: 0.5s;
}

.readMore:hover {
  transition: 0.5s;
  transform: translateY(10px); 
}
