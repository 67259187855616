.slide3-slideshow {
  position: relative;
  width: 100%;
  height: 100%;
}

.slide3-slider {
  display: flex;
  justify-content: space-between;
  transition: transform 0.5s ease;
  padding-left: 60px;
  padding-right: 60px;
}

.slide3-slide {
  width: calc(100% / 3 - 20px);
  max-height: 100%;
  object-fit: contain;
  margin: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
}

.slide3-control {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 36px;
  font-weight: bold;
  padding: 10px 15px;
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
  border: 2px solid black;
  cursor: pointer;
  outline: none;
  z-index: 2;
}

.slide3-control:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

.slide3-prev {
  left: 5px;
}

.slide3-next {
  right: 5px;
}
