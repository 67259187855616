.navmenulink {
  color: white;
  border-bottom: 1px solid rgb(123, 123, 123);
  font-family: 'Montserrat', sans-serif;
  font-size: 13px;
  padding: 8px 0;
}

.navmenulink:hover {
  color: rgb(124, 204, 3);
}