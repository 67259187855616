.slide1-slideshow {
  position: relative;
  margin: auto;
  width: 100%;
  height: 500px;
  overflow: hidden;
  outline: none;
  /* remove the default focus outline */
}

.slide1-slideshow img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.slide1-prev,
.slide1-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 36px;
  font-weight: bold;
  padding: 10px 15px;
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
  border: 2px solid white;
  cursor: pointer;
  outline: none;
  z-index: 2;
}

.slide1-prev:hover,
.slide1-next:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

.slide1-prev {
  left: 0;
}

.slide1-next {
  right: 0;
}

.slide1-caption {
  position: absolute;
  text-align: center;
  width: 100%;
  height: 100%;

  background-color: rgba(0, 0, 0, 0.1);
  color: white;
  font-size: 20px;
}

@media (max-width: 576px) {
  .slide1-caption * {
    font-size: large;
  }
}

.slide1-caption a {
  /* border: 2px solid white; */
  background-color: transparent;
  color: rgba(0, 0, 0, 0.9) !important;
  /* font-weight: bold; */
  font-size: 17px !important;
  padding: 10px 20px;
  text-decoration: none;
  background-color: rgb(254, 172, 21);
}



.slide1-caption a:visited {
  color: white;
}

.kenburns-top {
  -webkit-animation: kenburns-top 5s ease-out both;
  animation: kenburns-top 5s ease-out both;
}

/* ----------------------------------------------
 * Generated by Animista on 2023-3-6 21:28:6
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation kenburns-top
 * ----------------------------------------
 */
@-webkit-keyframes kenburns-top {
  0% {
    -webkit-transform: scale(1) translateY(0);
    transform: scale(1) translateY(0);
    -webkit-transform-origin: 50% 16%;
    transform-origin: 50% 16%;
  }

  100% {
    -webkit-transform: scale(1.25) translateY(-15px);
    transform: scale(1.25) translateY(-15px);
    -webkit-transform-origin: top;
    transform-origin: top;
  }
}

@keyframes kenburns-top {
  0% {
    -webkit-transform: scale(1) translateY(0);
    transform: scale(1) translateY(0);
    -webkit-transform-origin: 50% 16%;
    transform-origin: 50% 16%;
  }

  100% {
    -webkit-transform: scale(1.25) translateY(-15px);
    transform: scale(1.25) translateY(-15px);
    -webkit-transform-origin: top;
    transform-origin: top;
  }
}
