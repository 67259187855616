.contactButton-md {
  background-color: rgb(117, 188, 11);
  border: 1px dotted rgb(21, 21, 21);
  border-radius: 1px;
  color: white;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 25px;
  padding-right: 25px;
  font-size: large;
  font-weight: bold;
}

.contactButton-sm {
  background-color: rgb(117, 188, 11);
  border: 1px dotted rgb(21, 21, 21);
  border-radius: 1px;
  color: white;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 15px;
  padding-right: 15px;
  font-size: x-small;
  font-weight: bold;
}

.contactButton-div {
  background-color: rgb(117, 188, 11);
  width: fit-content;
  padding: 2px;
  border-radius: 1px;
  border: 1px solid green;
  height: fit-content;
}
