.contactForm-title {
  font-size: x-large;
  padding: 0 0 10px 30px;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
}

@media (max-width: 992px) {
  .contactForm-div {
    width: 100%;
    height: 600px;
    padding-bottom: 50px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }

  .contactForm-div input {
    width: 100%;
    height: 50px;
    margin-bottom: 20px;
    border: 1px solid rgb(216, 216, 216);
    border-radius: 0px;
    padding-left: 20px;
  }

  .contactForm-div textarea {
    width: 100%;
    border: 1px solid rgb(216, 216, 216);
    border-radius: 0px;
    padding-left: 20px;
    margin-bottom: 3px;
  }

  .contactForm-formdiv {
    margin: 0 auto;
    width: 95%;
    height: 90%;
    display: flex;
  }

  .contactForm-form {
    width: 95%;
    height: 85%;
    margin: 15px auto;
  }
}

@media (min-width: 992px) {
  .contactForm-div {
    width: 50%;
    padding: 5px;
    padding-bottom: 50px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }

  .contactForm-formdiv {
    margin: 0 auto;
    width: 90%;
    height: 90%;
    display: flex;
  }

  .contactForm-form {
    width: 95%;
    height: 85%;
    margin: auto;
    padding-left: 15px;
  }

  .contactForm-div input {
    width: 46%;
    height: 45px;
    margin-right: 4%;
    margin-bottom: 20px;
    border: 2px solid rgb(242, 242, 242);
    border-radius: 2px;
    padding-left: 20px;
  }

  .contactForm-div textarea {
    width: 96%;
    border: 1px solid rgb(216, 216, 216);
    border-radius: 0px;
    padding-left: 20px;
    margin-bottom: 3px;
  }
}

.contactForm-div input::placeholder {
  color: rgb(204, 204, 204);
}

.contactForm-button {
  padding: 5px 30px;
  border: 0;
  background-color: rgb(117, 188, 11);
  color: white;
  margin: 0 auto;
}
